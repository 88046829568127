<template>
  <div class="period">
    <div class="flex j-w" v-if="courseWatchDtos.length > 0">
      <div class="period-circle flex conet">
        <div class="period-box con conet">
          <div class="large-size">{{ allCredit }}</div>
          <div>已获学时</div>
        </div>
      </div>
      <div class="f-table">
        <div class="flex conet f-header">
          <div class="h-course">课程名称</div>
          <div class="h-cont">性质</div>
          <div class="h-cont">获取学时</div>
          <div class="h-cont">活动证书</div>
          <div class="h-cont">获取时间</div>
        </div>
        <div
          v-for="(itme, index) in courseWatchDtos"
          :key="index"
          :class="['f-hight', activeName == index ? 'f-hight-active' : '']"
          :style="{
            height:
              activeName == index ? 60 + 60 * itme.list.length + 'px' : '60px',
          }"
        >
          <div
            :class="['flex', 'conet', 'hight']"
            @click="triangleSkip(itme, index)"
          >
            <div
              :class="[
                'triangle',
                activeName == index ? 'triangle-active' : '',
              ]"
            ></div>
            <div class="h-course">{{ itme.activityName }}</div>
            <div class="h-cont">{{ itme.tag | course_classification }}</div>
            <div class="h-cont bd">{{ itme.nowStudyHour }}</div>
            <div
              class="h-cont"
              style="cursor: pointer"
              @click="examineIncident(itme, index)"
            >
              暂无证书
            </div>
            <div class="h-cont" style="font-size: 12px">
              {{
                itme.studyHourTime | timeFormatter("{year}年{month}月{day}日")
              }}
            </div>
          </div>
          <div
            class="collapse-box flex conet"
            v-for="(itmes, indexs) in itme.list"
            :key="indexs"
          >
            <div class="bd"></div>
            <div class="c-course" @click="collapseIncident(itme, index)">
              {{ itmes.courseName }}
            </div>
            <div class="c-time"></div>
            <div class="c-time">{{ itmes.studyHour }}</div>
            <div class="c-time"></div>
            <div class="c-time" style="font-size: 12px">
              {{ itmes.data | timeFormatter("{year}年{month}月{day}日") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-else>
      <AbsolutelyEmpty
        title="暂无获取相关学时哦！"
        :img="periodImg"
      ></AbsolutelyEmpty>
    </template>
    <!-- 分页 -->
    <div class="page" v-if="currentTotal > 5">
      <el-pagination
        background
        :page-size="pageSize"
        layout="prev, pager, next"
        @current-change="currentChange"
        :total="currentTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import Utils from '@/utils';
import list from '@/mixins/list';
import { timeFormatter } from '@/utils/verify';
import { personalCenterMyCredit } from '@/api/personal';
import { course_classification } from '@/config/statusCode';
import periodImg from '@/assets/images/personage/xue-shi.png';
import AbsolutelyEmpty from '@/components/AbsolutelyEmpty/index';
export default {
  name: 'Period',
  components: {
    AbsolutelyEmpty
  },
  filters: {
    timeFormatter,
    course_classification
  },
  mixins: [list],
  props: {
    personageTime: {
      type: Number,
      default: new Date().getFullYear()
    }
  },
  data() {
    return {
      courseWatchDtos: [],
      allCredit: 0,
      activeName: 0,
      pageSize: 5,
      currentTotal: 0,
      periodImg: periodImg
    };
  },
  watch: {
    personageTime(vol) {
      this.pageNum = 1;
      this.getPersonalCenterMyCredit();
    }
  },
  methods: {
    loadData() {
      this.getPersonalCenterMyCredit();
    },
    getPersonalCenterMyCredit() {
      const jsonData = this.getParams();
      jsonData.time = this.personageTime;
      personalCenterMyCredit(jsonData).then((res) => {
        if (res.code == 200) {
          const { courseWatchDtos, allCredit, total } = res.data;
          this.courseWatchDtos = courseWatchDtos;
          this.allCredit = allCredit;
          this.currentTotal = total;
        }
      });
    },
    collapseIncident(itme, index) {
      this.$router.push({
        path: '/courseDetails',
        query: {
          id: itme.id
        }
      });
    },
    triangleSkip(itme, index) {
      if (this.activeName == index) {
        this.activeName = -1;
        return;
      }
      this.activeName = index;
    },
    examineIncident(itme, index) {
      Utils.msgWarning('暂无证书');
      // return
      // this.$router.push({
      //     path: '/certificate',
      //     query: {
      //         id: itme.id
      //     }
      // });
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.period {
  box-sizing: border-box;
  padding-top: 32px;
  padding-bottom: 87px;
  width: 100%;
  min-height: 600px;
  .period-circle {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: 2px solid rgba(0, 189, 146, 0.24);
    justify-content: center;
    .period-box {
      width: 280px;
      height: 280px;
      background: #00d0a0;
      border-radius: 50%;
      justify-content: center;
      color: @TEXT-COLOR-FFFFFF;
      .fontSize(@TEXT-SIZE-18);
      .large-size {
        .fontSize(@TEXT-SIZE-120);
        line-height: 180px;
      }
    }
  }
  .f-table {
    width: 783px;
    box-sizing: border-box;
    .f-header {
      box-sizing: border-box;
      padding: 0 20px;
      height: 60px;
      background: @TEXT-BG-F4F4F4;
      .fontSize(@TEXT-SIZE-16) !important;
      font-weight: 500;
      .h-course {
        width: 350px;
        color: @TEXT-COLOR-363636;
      }
      .h-cont {
        flex: 1;
        color: @TEXT-COLOR-363636;
        text-align: center;
      }
    }
    .f-hight {
      height: 60px;
      box-sizing: border-box;
      padding: 0 20px;
      border-bottom: 1px solid #e6e6e6;
      overflow: hidden;
      background: #fff;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      .hight {
        height: 60px;
      }
      .fontSize(@TEXT-SIZE-14) !important;
      .triangle {
        width: 16px;
        height: 16px;
        background: url("~@/assets/images/home/san-jiao-xing.png") no-repeat 0px
          0px;
        cursor: pointer;
        transform: rotate(0deg);
        transition: all 0.3s ease-in-out;
      }
      .triangle-active {
        transform: rotate(-180deg);
        transition: all 0.3s ease-in-out;
      }
      .h-course {
        width: 320px;
        margin-left: 14px;
        color: @TEXT-COLOR-363636;
      }
      .h-cont {
        flex: 1;
        color: @TEXT-COLOR-888888;
        text-align: center;
      }
      .bd {
        .fontSize(@TEXT-SIZE-18) !important;
        color: @--color-primary;
      }
    }
    .f-hight-active {
      transition: all 0.3s ease-in-out;
    }
    .c-course {
      width: 297px;
      .fontSize(@TEXT-SIZE-14);
      color: @TEXT-COLOR-363636;
      box-sizing: border-box;
    }
    .c-time {
      flex: 1;
      color: @TEXT-COLOR-888888;
      text-align: center;
      .fontSize(@TEXT-SIZE-14);
    }
    .collapse-box {
      height: 60px;
      .border-direction(top,1px,solid,@TEXT-BG-F4F4F4);
      box-sizing: border-box;
      padding: 0 0 0 20px;
      cursor: pointer;
      .bd {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: @--color-primary;
        margin: 0 10px 0 18px;
      }
      .text {
        color: @TEXT-COLOR-888888;
        .fontSize(@TEXT-SIZE-14);
      }
    }
  }
  .page {
    height: 138px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    padding-bottom: 90px;
    margin-top: 40px;
    /deep/.number,
    /deep/.btn-prev,
    /deep/.btn-next {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      text-align: center;
      line-height: 40px;
      &.active {
        background: @--color-primary !important;
      }
    }
    /deep/.el-pager li:hover {
      color: @--color-primary;
    }
    /deep/.el-pager li.active:hover {
      color: @TEXT-COLOR-FFFFFF;
    }
  }
}
</style>