<template>
  <div class="sign-in">
    <template v-if="courseWatchDtos.length > 0">
      <div class="sign-table" v-loading="loading">
        <div class="flex conet f-header">
          <div class="h-course">课程名称</div>
          <div class="h-cont" style="width: 120px">性质</div>
          <div class="h-cont bo">培训时间</div>
          <div class="h-cont bo-s">培训地点</div>
          <div class="h-cont bo">报到时间</div>
          <div class="h-cont">签到详情</div>
        </div>
        <div
          v-for="(itme, index) in courseWatchDtos"
          :key="index"
          :class="['f-hight', activeName == index ? 'f-hight-active' : '']"
          :style="{
            height:
              activeName == index
                ? 60 + 60 * itme.courseSignInDtoList.length + 'px'
                : '60px',
          }"
        >
          <div
            :class="['flex', 'conet', 'hight']"
            @click="triangleSkip(itme, index)"
          >
            <div class="h-course">{{ itme.activityName }}</div>
            <div class="h-cont" style="width: 120px">面授课</div>
            <div class="h-cont bo">
              {{
                itme.activityStartTime
                  | timeFormatter("{year}年{month}月{day}日")
              }}
              ~ {{ itme.activityEndTime | timeFormatter("{month}月{day}日") }}
            </div>
            <div class="h-cont bo-s">{{ itme.address }}</div>
            <div class="h-cont bo" v-if="itme.registStatus === 'SIGNED_UP'">
              <div class="reported">已报到</div>
              <div class="h-cont-text">
                {{
                  itme.time
                    | timeFormatter("{year}年{month}月{day}日 {hour}:{minute}")
                }}
              </div>
            </div>
            <div class="h-cont bo" v-else>
              <div class="isreported">未报到</div>
            </div>
            <div class="h-cont reported" v-if="itme.isAccomplish">全勤</div>
            <div class="h-cont isreported" v-else>缺勤</div>
            <div
              :class="[
                'triangle',
                'h-cont-flex',
                activeName == index ? 'triangle-active' : '',
              ]"
            ></div>
          </div>
          <div
            class="collapse-box flex conet"
            v-for="(itmes, indexs) in itme.courseSignInDtoList"
            :key="indexs"
          >
            <div class="bd"></div>
            <div class="c-course" @click="collapseIncident(itme, index)">
              {{ itmes.name }}
            </div>
            <div class="c-time">
              <div v-if="itmes.state === 'WATCH'" class="reported">已签到</div>
              <div v-else class="isreported">未签到</div>
              <div class="c-text-time" v-if="itmes.state === 'WATCH'">
                {{
                  itmes.data
                    | timeFormatter("{year}年{month}月{day}日 {hour}:{minute}")
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page" v-if="currentTotal > 10">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="currentChange"
          :total="currentTotal"
        >
        </el-pagination>
      </div>
    </template>
    <template v-else>
      <AbsolutelyEmpty title="暂无签到信息！" :img="signImg"></AbsolutelyEmpty>
    </template>
  </div>
</template>
<script>
import AbsolutelyEmpty from '@/components/AbsolutelyEmpty/index';
import { timeFormatter } from '@/utils/verify';
import { getSignIn } from '@/api/personal';
import list from '@/mixins/list';
import signImg from '@/assets/images/personage/xue-shi.png';
export default {
  name: 'Period',
  components: {
    AbsolutelyEmpty
  },
  filters: {
    timeFormatter
  },
  mixins: [list],
  props: {
    personageTime: {
      type: Number,
      default: new Date().getFullYear()
    }
  },
  data() {
    return {
      courseWatchDtos: [],
      allCredit: 0,
      activeName: 0,
      signImg: signImg
    };
  },
  watch: {
    personageTime(vol) {
      this.pageNum = 1;
      this.getGetSignIn();
    }
  },
  created() {},
  methods: {
    loadData() {
      this.getGetSignIn();
    },
    getGetSignIn() {
      const jsonData = this.getParams();
      jsonData.time = this.personageTime;
      getSignIn(jsonData).then((res) => {
        if (res.code == 200) {
          const { list, total } = res.data;
          this.currentTotal = total;
          this.loading = false;
          this.courseWatchDtos = list;
        }
      });
    },
    collapseIncident(itme, index) {
      this.$router.push({
        path: '/courseDetails',
        query: {
          id: itme.id
        }
      });
    },
    triangleSkip(itme, index) {
      if (this.activeName == index) {
        this.activeName = -1;
        return;
      }
      this.activeName = index;
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.sign-in {
  box-sizing: border-box;
  padding-top: 32px;
  padding-bottom: 87px;
  width: 100%;
  min-height: 600px;
  .page {
    height: 138px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    padding-bottom: 90px;
    margin-top: 40px;
    /deep/.number,
    /deep/.btn-prev,
    /deep/.btn-next {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      text-align: center;
      line-height: 40px;
      &.active {
        background: @--color-primary !important;
      }
    }
    /deep/.el-pager li:hover {
      color: @--color-primary;
    }
    /deep/.el-pager li.active:hover {
      color: @TEXT-COLOR-FFFFFF;
    }
  }
  .sign-table {
    width: 100%;
    box-sizing: border-box;
    .f-header {
      box-sizing: border-box;
      padding: 0 20px;
      height: 60px;
      background: @TEXT-BG-F4F4F4;
      .fontSize(@TEXT-SIZE-16) !important;
      font-weight: 500;
      .h-course {
        width: 200px;
        color: @TEXT-COLOR-363636;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .h-cont {
        width: 160px;
        color: @TEXT-COLOR-363636;
        text-align: center;
        line-height: 20px;
      }
    }
    .f-hight {
      height: 60px;
      box-sizing: border-box;
      padding: 0 20px;
      border-bottom: 1px solid #e6e6e6;
      overflow: hidden;
      background: #fff;
      transition: all 0.3s ease-in-out;
      position: relative;
      cursor: pointer;
      .hight {
        height: 60px;
      }
      .fontSize(@TEXT-SIZE-14) !important;
      .triangle {
        width: 16px;
        height: 16px;
        background: url("~@/assets/images/home/san-jiao-xing.png") no-repeat 0px
          0px;
        cursor: pointer;
        transform: rotate(0deg);
        transition: all 0.3s ease-in-out;
      }
      .triangle-active {
        transform: rotate(-180deg);
        transition: all 0.3s ease-in-out;
      }
      .h-course {
        width: 200px;
        color: @TEXT-COLOR-363636;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .h-cont {
        width: 160px;
        color: @TEXT-COLOR-888888;
        text-align: center;
        line-height: 20px;
        .h-cont-text {
          font-size: 12px;
          margin-top: 10px;
        }
      }
      .h-cont-flex {
        position: absolute;
        top: 22px;
        right: 32px;
      }
    }
    .f-hight-active {
      transition: all 0.3s ease-in-out;
    }
    .c-course {
      flex: 1;
      .fontSize(@TEXT-SIZE-14);
      color: @TEXT-COLOR-363636;
      box-sizing: border-box;
    }
    .c-time {
      width: 160px;
      color: @TEXT-COLOR-888888;
      text-align: center;
      .fontSize(@TEXT-SIZE-14);
      .c-text-time {
        font-size: 12px;
        margin-top: 10px;
      }
    }
    .collapse-box {
      height: 60px;
      .border-direction(top,1px,solid,@TEXT-BG-F4F4F4);
      box-sizing: border-box;
      padding: 0 0 0 20px;
      cursor: pointer;
      .bd {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: @--color-primary;
        margin: 0 10px 0 18px;
      }
      .text {
        color: @TEXT-COLOR-888888;
        .fontSize(@TEXT-SIZE-14);
      }
    }
  }
}
.bo {
  width: 200px !important;
}
.bo-s {
  width: 260px !important;
}
.reported {
  color: @--color-primary !important;
}
.isreported {
  color: #ff5102 !important;
}
</style>