<template>
  <div class="study-table">
    <div class="study-delete">
      <template v-if="list.length > 0">
        <div class="delete" @click="deleteStudyRecord">
          <i class="el-icon-delete"></i>
          清空记录
        </div>
      </template>
      <template v-else>
        <div></div>
      </template>
    </div>
    <template v-if="list.length > 0">
      <el-table
        :data="list"
        style="width: 100%"
        max-height="660"
        v-loading="loading"
      >
        <el-table-column label="课程名称" width="600">
          <template slot-scope="scope">
            <span style="cursor: pointer" @click="nrmkldkahLook(scope.row)">
              {{ scope.row.activityName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="性质" align="center" width="110">
          <template slot-scope="scope">
            <span>{{ scope.row.tag | course_classification }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="nowStudyHour"
          label="学时"
          align="center"
          width="110"
        >
        </el-table-column>
        <el-table-column
          prop="entryTime"
          label="报名时间"
          align="center"
          width="130"
        >
          <template slot-scope="scope">
            <span class="text">{{
              scope.row.entryTime | timeFormatter("{year}年{month}月{day}日")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="studyRecordTime"
          label="学习时间"
          align="center"
          width="130"
        >
          <template slot-scope="scope">
            <span class="text">{{
              scope.row.studyRecordTime
                | timeFormatter("{year}年{month}月{day}日")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="110">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="nrmkldkahLook(scope.row)"
            >
              查看详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page" v-if="currentTotal > 10">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="currentChange"
          :total="currentTotal"
        >
        </el-pagination>
      </div>
    </template>
    <template v-else>
      <AbsolutelyEmpty
        title="暂无学习记录哦！"
        :img="studyImg"
      ></AbsolutelyEmpty>
    </template>
  </div>
</template>
<script>
import Utils from '@/utils';
import { Loading } from 'element-ui';
import {
  personalCenterMyPersonalStudyRecord,
  delStudyRecord
} from '@/api/personal';
import list from '@/mixins/list';
import { timeFormatter } from '@/utils/verify';
import { course_classification } from '@/config/statusCode';
import studyImg from '@/assets/images/curriculum/empty-114.png';
import AbsolutelyEmpty from '@/components/AbsolutelyEmpty/index';
export default {
  name: 'StudyTable',
  components: {
    AbsolutelyEmpty
  },
  filters: {
    timeFormatter,
    course_classification
  },
  mixins: [list],
  props: {
    personageTime: {
      type: Number,
      default: new Date().getFullYear()
    }
  },
  data() {
    return {
      studyImg: studyImg
    };
  },
  watch: {
    personageTime(vol) {
      this.pageNum = 1;
      this.getPersonalCenterMyPersonalStudyRecord();
    }
  },
  methods: {
    // 清空记录
    deleteStudyRecord() {
      this.$trainPopup({
        title: '确认后可清空本年的所有学习记录',
        leftBtn: '取消',
        rightBtn: '确认'
      }).then((res) => {
        delStudyRecord({
          time: this.personageTime
        }).then((res) => {
          if (res.code == 200) {
            this.$loading();
            setTimeout(() => {
              this.$loading().close();
              Utils.msgSuccess('清空记录成功');
              this.pageNum = 1;
              this.getPersonalCenterMyPersonalStudyRecord();
            }, 1500);
          }
        });
      });
    },
    // 获取我的学习记录数据
    getPersonalCenterMyPersonalStudyRecord() {
      const jsonData = this.getParams();
      jsonData.time = this.personageTime;
      personalCenterMyPersonalStudyRecord(jsonData).then((res) => {
        if (res.code == 200) {
          const { list, total } = res.data;
          this.currentTotal = total;
          this.list = list;
          this.loading = false;
        }
      });
    },
    loadData() {
      this.getPersonalCenterMyPersonalStudyRecord();
    },
    nrmkldkahLook(e) {
      this.$router.push({
        path: '/courseDetails',
        query: {
          id: e.id
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.study-table {
  min-height: 450px;
  margin-top: 32px;
  width: 100%;
  position: relative;
  margin-bottom: 30px;
  .study-delete {
    position: absolute;
    left: 0px;
    top: -60px;
    z-index: 1000000;
    .delete {
      color: #888888;
      font-size: 14px;
      cursor: pointer;
    }
    .delete:hover {
      color: #ff5102;
    }
  }
  .page {
    height: 138px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    padding-bottom: 90px;
    margin-top: 40px;
    /deep/.number,
    /deep/.btn-prev,
    /deep/.btn-next {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      text-align: center;
      line-height: 40px;
      &.active {
        background: @--color-primary !important;
      }
    }
    /deep/.el-pager li:hover {
      color: @--color-primary;
    }
    /deep/.el-pager li.active:hover {
      color: @TEXT-COLOR-FFFFFF;
    }
  }
  /deep/.el-table th,
  .el-table tr {
    background: #f4f4f4;
    text-align: center;
    font-size: 16px;
    color: #363636;
    font-weight: bold !important;
  }
  /deep/.el-table th:first-child,
  .el-table tr:first-child {
    text-align: left;
  }
  /deep/.el-table__row,
  /deep/.has-gutter,
  /deep/.el-table__header,
  /deep/.el-table__header-wrapper {
    height: 60px;
  }
  /deep/ tr {
    height: 60px;
  }
  /deep/ tr th {
    height: 60px;
  }
}
</style>